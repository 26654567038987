import React, { useEffect, useState, useRef } from "react";
import Excalidraw, {
    exportToSvg,
} from "@excalidraw/excalidraw";
import InitialData from "./initialData";
import { connect } from 'react-redux'
import "./apps.scss";
import initialData from "./initialData";
import { getSvg, whiteBoard } from "../Redux/Actions";
import { useTranslation } from 'react-i18next';

function Excali(props) {
    const excalidrawRef = useRef(null);
    const { t } = useTranslation();

    const [zenModeEnabled, setZenModeEnabled] = useState(true);
    const [exportWithDarkMode, setExportWithDarkMode] = useState(false);
    const [shouldAddWatermark, setShouldAddWatermark] = useState(false);
    const [theme, setTheme] = useState("light");
    const [initialvalue, setInitialvalue] = useState("")

    const changeHandler = (elements, state) => {
        handleSVG();
        props.board(elements)
    }

    useEffect(() => {
        let elementdata = {...InitialData} ;
        elementdata.elements = props.whiteBoard || [];
        setInitialvalue(elementdata);
    }, [props.whiteBoard]);



    const convertToPlain = (html) => {
        let tempDivElement = document.createElement("div");
        tempDivElement.innerHTML = html;
        return tempDivElement.textContent || tempDivElement.innerText || "";
    }


    const handleSVG = async e => {
        let elements = excalidrawRef?.current?.getSceneElements();
        if (elements.length) {
            const svg = await exportToSvg({
                elements: elements,
                appState: {
                    ...initialData.appState,
                    exportWithDarkMode,
                    shouldAddWatermark,
                    width: 800,
                    height: 600
                },
                embedScene: true
            });
            props.SVG(svg)
        }
    }

    const updateScene = () => {
        const sceneData = {
            elements: [
            ],
            appState: {
                // viewBackgroundColor: "#edf2"
            }
        };
        excalidrawRef.current.updateScene(sceneData);
    };


    return (
        <div className="App">
                {props.isViewPage? <></> :
                    <div className="button-wrapper">
                        <button className="btn-clear" onClick={updateScene}>
                            {t("clear")}
                        </button>
                    </div>}
                <div className="excalidraw-wrapper" style={props.isViewPage? {height:"100.5%"} : {height:"95%"}}>
                    <Excalidraw
                        ref={excalidrawRef}
                        initialData={initialvalue}
                        onChange={(elements, state) =>
                            changeHandler(elements, state)
                        }
                        onPointerUpdate={(payload) => console.log()}
                        zenModeEnabled={zenModeEnabled}
                        viewModeEnabled={props.isViewPage? true : false}
                        theme={theme}
                        name="Custom name of drawing"
                        UIOptions={{ canvasActions: { loadScene: false } }}
                    />
                    <div style={{ display: "none" }}>
                        <button
                            onClick={handleSVG}
                        >

                        </button>
                    </div>
                </div>
        </div >
    );
}

const mapStateToProps = (state) => ({
    whiteBoard: state.whiteBoard
})

const mapDispatchToProps = dispatch => ({
    board: (value) => (dispatch(whiteBoard(value))),
    SVG: (data) => (dispatch(getSvg(data)))
})

export default connect(mapStateToProps, mapDispatchToProps)(Excali)
