import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { baseUrl } from "../utils/config";
import axios from "axios";
import Editor from "../Coder/Editor";
import SubmitSuccess from "../Coder/submitSuccess";
import { authToken } from "../utils/token";
import Loader from "../loader";
import { Loading } from "carbon-components-react";


function View() {
  const routeParams = useParams();
  const [isOTPvalidated, setIsOTPvalidated] = useState({ isViewPage: false, codingInterviewInfo: undefined });

  useEffect(() => {
    const { publicUuid } = routeParams || {};
    let url = `${baseUrl}/codinginterviews/get?publicUuid=${publicUuid}`;
    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios.get(url, config).then((res) => {
      const { data = {} } = res || {};
      const { list = {} } = data || {};
      const { codingInterviewId = {} } = list || {};
      validateApi(publicUuid, codingInterviewId)
    })
      .catch(error => {
        console.log(error);
      })
  }, [])

  const validateApi = (publicUuid) => {

    const validateOTPData = { publicUuid: publicUuid, enteredOtp: "test", bypassOtp: true };
    let url = `${baseUrl}/codinginterviews/otp/validate`;
    let Config = {
      headers: {
        "Content-Type": "application/json",
        // Authorization: authToken,
        "Access-Control-Allow-Origin": "*",
      },
    };
    axios
      .post(url, validateOTPData, Config)
      .then((res) => {
        const { data } = res || {};
        if (data) {
          let isViewPage;
          if (data.codingInterviewInfo.status === "completed") {
            isViewPage = true;
          }
          setIsOTPvalidated({ isViewPage: isViewPage, codingInterviewInfo: data });
        }

      })
      .catch(error => {
        console.log(error);
      })
  };

  return (
    <Fragment>
      {isOTPvalidated.isViewPage ?
        <div>
          <Editor validatedData={isOTPvalidated.codingInterviewInfo} isViewPage={isOTPvalidated.isViewPage} />
        </div>
        :
        <div className="viewLoader">
          <Loader />
        </div>
      }
    </Fragment>
  )
}

export default View;