import { Component } from "react";
import { connect } from 'react-redux'
import { getHybridQuestionId, getSelectedIndex, Questions } from "../Redux/Actions";
import "./options.css"
import "../Coder/Editor.css"

class Question extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editor: "",
            session: "",
            bool: true,
            questions: '',
            question: [],
            input: "[2,3,5]",
            questionId: ""
        }
    }

    stripHtml(html) {
        let tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    }

    componentDidUpdate(prevProps) {

        if (prevProps.questionData !== this.props?.questionData) {
                    let question = this.props?.questionData;
                    this.setState({ question: question }, () => {
                        this.validatedQuestions( this.props.hybridQuestionIndex || 0);
                    });
                }
    }

    componentDidMount() {
        let question = this.props?.questionData;
        this.setState({question: question} , () => {
            this.validatedQuestions( this.props.hybridQuestionIndex || 0);
        }) ;
    }

    // //// Create ACE
    // var editor = window.ace.edit("ques-input-container");
    // editor.setValue(this.state.input ? this.state.input : "");
    // editor.session.setMode("ace/mode/javascript");
    // editor.setTheme("ace/theme/monokai");
    // editor.renderer.setShowGutter(false);
    // editor.setReadOnly(true)
    // editor.setShowPrintMargin(false);
    // editor.session.selection.clearSelection();
    // editor.setOptions({
    //     readOnly: true,
    //     highlightActiveLine: false,
    //     highlightGutterLine: false
    // })

    // getExampleRef() {
    //     var ref = window.firebase.database().ref();
    //     var hash = window.location.hash.replace(/#/g, '');
    //     if (hash) {
    //         ref = ref.child(hash);
    //     } else {
    //         ref = ref.push(); // generate unique location.
    //         window.location = window.location + '#' + ref.key; // add it as a hash to the URL.
    //     }
    //     if (typeof console !== 'undefined') {
    //         console.log('Firebase data: ', ref.toString());
    //     }
    //     return ref;
    // }

    getquestionValue = (e) => {
        let index = e.target.value
        this.validatedQuestions(index);
    }

    validatedQuestions = (index) => {
        let { question } = this.state;
        if(question){
            let respQuestions = question[index];
            this.setState({ questionId: respQuestions?.id, questions: this.stripHtml(respQuestions.hybridQuestionName) });
            if(respQuestions.hybridQuestionId !== this.props.hybridQuestionId){
                this.props.hybridQuestionIds(respQuestions.hybridQuestionId);
            }
        }
        this.props.selectedIndex(index);
    }

    render() {
        let { question, questionId, questions } = this.state;
        return (
            <>
                <div className="textarea">
                    <div className="row">
                        <div className="col-sm-10">
                            <p>{questions}</p>
                        </div>
                        <div className="col-sm-2">
                            {question?.length > 1 && question?.map((obj, index) => {
                                return <button index={index} className= { (questionId === obj.id ) ? "activeButton" : "defaultButton" } key={obj.id} onClick={this.getquestionValue} value={index}> {index + 1} </button>
                            })}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    selectedQuestionIds: state.selectedQuestion,
    hybridQuestionIndex : state.hybridQuestionIndex,
    hybridQuestionId : state.hybridQuestionId
})

const mapDispatchToProps = dispatch => ({
    data: (value) => (dispatch(Questions(value))),
    hybridQuestionIds: (value) => (dispatch(getHybridQuestionId(value))),
    selectedIndex : (value) => (dispatch(getSelectedIndex(value)))
})

export default connect(mapStateToProps, mapDispatchToProps)(Question)
