import React from "react";
import x0paWhite from "../assets/images/x0pa-white.png";
import "../components/candidate/otpPage.css";
import { useTranslation } from 'react-i18next';

function SubmitSuccess() {
    const { t } = useTranslation();
    return (
        <div className="bx--grid m-0 p-0 xpa-overflow--hidden m-0 p-0">
            <div className="pt-0">
                <div
                    className="bx--row sub_div_height_full overflow-hidden"
                    style={{
                        minHeight: "100vh",
                    }}
                >
                    <div
                        style={{
                            backgroundColor: "#623854",
                        }}
                        className="bx--col-lg-6 bx--col-md-12 border-page-division bx--col-md-12 d-flex align-items-center"
                    >
                        <div className="d-flex flex-fill justify-content-center">
                            <img className="img_height" src={x0paWhite} alt="x0pa" />
                        </div>
                    </div>
                    <div
                        className="bx--col-lg-6 bx--col-md-12 d-flex flex-column justify-content-center"
                        style={{ minHeight: "100vh" }}
                    >
                        <div className="mt-2 font-weight-bold h6">
                            {t("Your interview response has been submitted successfully.")}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SubmitSuccess;