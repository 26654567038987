export const Note = (value) => ({
    type: 'NOTES',
    value: value
})

export const Support = (value) => ({
    type: 'SUPPORT',
    value: value
})

export const Runcode = (value) => ({
    type: 'RUNCODE',
    value: value
})

export const Questions = (value) => ({
    type: 'QUESTION',
    value: value
})

export const whiteBoard = (value) => ({
    type: 'WHITEBOARD',
    value: value
})

export const Result = (value) => ({
    type: 'RESULT',
    value: value
})

export const getSvg = (value) => ({
    type: 'GETSVG',
    value: value
})

export const getHybridQuestionId = (value) => ({
    type: 'HYBRIDQUESTIONID',
    value: value
})

export const getSelectedIndex = (value) => ({
    type: 'SELECTEDINDEX',
    value: value
})

export const submitCompleted = (value) => ({
    type: 'SUBMITCOMPLETED',
    value: value
})

export const REQUESTLANGUAGECHANGE = (value) => ({
    type: 'REQUEST_LANGUAGE_CHANGE',
    payload: value
}) 