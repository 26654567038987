import React from "react";
import { Routes as Switch, Route } from "react-router-dom";
import CandidateCodingInterviewOTP from "../components/candidate/CandidateCodingInterviewOTP";
import i18nHelper from '../Coder/i18n';
import View from "../components/CandidateView";

const Routes = (props) => {
  i18nHelper(0);
  return (
    <div>
      <Switch>
        <Route
          path={"/public/codinginterviews/interview/:publicUuid"}
          element={<CandidateCodingInterviewOTP {...props} />}
        />
        <Route
          path={"/public/codinginterviews/view/:publicUuid"}
          element={<View {...props} />}
        />
      </Switch>
    </div>
  );
};

export default Routes;
