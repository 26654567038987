import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const initiateTranslation = function (companyId) {
  let ns = ['translation'];
  if (companyId && companyId != '0') {
    ns = [companyId, 'translation'];
  }
  let options = {
    // Put these locale translations in the public dir
    // resources,
    ns: ns,
    defaultNS: 'translation',
    fallbackNS: 'translation',
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  };

  // const translationEn = { question : "Question" }
  // const translationAr = { question : "سؤال" }


  // let options={
  //   resources:{
  //     en:{translation: translationEn},
  //     ar:{translation: translationAr}
  //   },
  //   lng:"en",
  //   fallbackLng: 'en',
  //   interpolation: {
  //     escapeValue: false,
  //   },
  // }
  i18n
    .use(XHR)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init(options);
};

export default initiateTranslation;
