// import Editor from "./Coder/Editor";
// import "./Coder/Editor.css";
import { createStore } from "redux";
import { Provider } from "react-redux";
import rootReducers from "./Redux/RootReducer";
import Routes from "./routes/index";
import "./Coder/i18n";
import { Suspense } from "react";

const persistedState = JSON.parse(localStorage.getItem("state"));

const store = createStore(rootReducers, persistedState ?? {});

store.subscribe(() => {
  localStorage.setItem("state", JSON.stringify(store.getState()));
});

function App(props) {
  return (
    <Suspense fallback="Loading...">
      <Provider store={store}>
        <Routes {...props} />
      </Provider>
    </Suspense>
  );
}

export default App;
