import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import {
    Switcher,
    SwitcherItem,
} from 'carbon-components-react';
import { REQUESTLANGUAGECHANGE } from '../Redux/Actions';
const instance = i18n.init();
// import './languageChange.css';

class LanguageChange extends Component {
    constructor(props) {
        super(props);
        this.state = {
            languages: [
                {
                    value: 'en',
                    label: 'English',
                },
                {
                    value: 'es',
                    label: 'Spanish',
                },
                {
                    value: 'ar',
                    label: 'Arabic',
                },
                { value: "it", label: "Italian" },
                { value: "ja", label: "Japanese" },
            ],
        };
    }

    componentDidMount() {
        const language = this.props?.selectedLang;
        const lang = this.props?.selectedLang
        if (language && language !== null) this.props.languageChange(language);
        i18n?.changeLanguage(lang === null ? 'en' : lang);
    }

    onLanguageChange = (id, value) => {
        this.props.languageChange(value);
        i18n?.changeLanguage(value.value);
        this.props.onchangeLanguage();
    };

    render() {
        let { languages } = this.state;
        
        return (
            <Fragment>
                {/* {showLanguageModal === true && ( */}
                <Switcher aria-label="Language switcher">
                    {languages &&
                        Array.isArray(languages) &&
                        languages.length > 0 &&
                        languages.map((res) => {
                            const { label } = res || {};
                            return (
                                <SwitcherItem
                                    key={label}
                                    aria-label="Language"
                                    onClick={() => this.onLanguageChange(null, res)}
                                    className="text-center">
                                    {label}
                                </SwitcherItem>
                            );
                        })}
                </Switcher>
                {/* )} */}
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    //user: state.x0paDuser.user,
    language: state.ProductLanguage.language,
    languages: state.ProductLanguage.languages,
    selectedLang: state.ProductLanguage.selectedLang,
});

const mapDispatchToProps = dispatch => ({
    languageChange: (value) => (dispatch(REQUESTLANGUAGECHANGE(value))),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LanguageChange));