import { useEffect, useState } from "react";
import Loader from "../loader";
import "./options.css"

function Results(props) {
    const [loader, setLoader] = useState(false);
    const [resultData, setResultData] = useState("")

    useEffect(() => {
        
        if (props.result === true) {
            setLoader(true)
        }
        else {
            setLoader(false)
            setResultData(props.output)
        }

    }, [props.result]);

    return (
        <>
            <div className="textarea">
                {loader ? <Loader /> :
                    <span>
                        {resultData}
                    </span>
                }
            </div>

        </>
    );
}

export default Results;
