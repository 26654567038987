export const notesReducer = (state = '', action) => {
    switch (action.type) {
        case 'NOTES':
            return state = action.value
        default:
            return state;
    }
}

export const supportReducer = (state = '', action) => {
    switch (action.type) {
        case 'SUPPORT':
            return state = action.value
        default:
            return state;
    }
}

export const runcodeReducer = (state = '', action) => {
    switch (action.type) {
        case 'RUNCODE':
            return state = action.value
        default:
            return state;
    }
}

export const questionReducer = (state = '', action) => {
    switch (action.type) {
        case 'QUESTION':
            return state = action.value
        default:
            return state;
    }
}

export const boardReducer = (state = '', action) => {
    switch (action.type) {
        case 'WHITEBOARD':
            return state = action.value
        default:
            return state;
    }
}

export const svgReducer = (state = '', action) => {
    switch (action.type) {
        case 'GETSVG':
            return state = action.value
        default:
            return state;
    }
}

export const resultReducer = (state = '', action) => {
    switch (action.type) {
        case 'RESULT':
            return state = action.value
        default:
            return state;
    }
}

export const hybridQuestionIdReducer = (state = '', action) => {
    switch (action.type) {
        case 'HYBRIDQUESTIONID':
            return state = action.value
        default:
            return state;
    }
}

export const selectedIndexIdReducer = (state = '', action) => {
    switch (action.type) {
        case 'SELECTEDINDEX':
            return state = action.value
        default:
            return state;
    }
}

export const submitCompleteReducer = (state = '', action) => {
    switch (action.type) {
        case 'SUBMITCOMPLETED':
            return state = action.value
        default:
            return state;
    }
}

const initState = {
    language: { value: 'en', label: 'English' },
    languages: [
      { value: 'en', label: 'English' },
      { value: 'zh', label: 'Chinese' },
      { value: 'es', label: 'Spanish' },
      { value: 'arb', label: 'Arabic' },
      { value: 'it', label: 'Italian' },
      { value: 'ja', label: 'Japanese' },
    ],
    selectedLang: 'en',
  };

 export const ProductLanguage = (state = initState, action) => {
    const { type, payload } = action;
    switch (type) {
      case 'REQUEST_LANGUAGE_CHANGE': {
        const { label, value } = payload || {};
        return {
          ...state,
          language: payload === {} ? state.language : payload,
          selectedLang: value || 'en',
        };
      }
      default:
        return state;
    }
  };
