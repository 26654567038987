import React, { Fragment, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "./otpPage.css";
import {
  TextInput,
  Button,
  InlineNotification,
  HeaderPanel,
} from "carbon-components-react";
import { Number_232, Number_132 } from "@carbon/icons-react";
import x0paWhite from "../../assets/images/x0pa-white.png";
import { useParams } from "react-router-dom";
import { baseUrl,dstaUrl } from "../../utils/config";
import Editor from "../../Coder/Editor";
import "../../Coder/Editor.css";
import { toast } from "react-toastify";
import axios from "axios";
import { authToken } from "../../utils/token";
import { useTranslation } from "react-i18next";
import LanguageChange from "../../Coder/Languages";

const CandidateCodingInterviewOTP = (props) => {
  const essentialsInit = {
    showSuccess: false,
    notifMsg: "",
    showError: false,
  };

  const routeParams = useParams();
  const [codiOTP, setCodiOTP] = useState("");
  const [isOTPvalidated, setIsOTPvalidated] = useState({
    bool: false,
    data: undefined,
    isViewPage: false,
  });
  const [essentials, setEssentials] = useState(essentialsInit);
  const [codiInterviewInfo, setCodiInterviewInfo] = useState({});
  const [isUserLogin,setUserLogin] = useState(false);
  const [candiProfile, setCandiProfile] = useState({
    firstName: "",
    lastName: "",
  });
  const [showTranslationChange, setShowTranslationChange] = useState(false);
  const selectedLang = useSelector(
    (state) => state.ProductLanguage.selectedLang
  );
  const { t: translation } = useTranslation();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const userLogin = searchParams.get('isUserLogin') || false;
    if(userLogin){
      setUserLogin(true)
      handleGettingData();
    }
    handleGetCodingInterview();
  }, []);

  const handleGetCodingInterview = () => {
    const { publicUuid } = routeParams || {};
    let url = `${baseUrl}/codinginterviews/get?publicUuid=${publicUuid}`;
    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios.get(url, config).then((res) => {
      const { data = {} } = res || {};
      //   setCodiInterviewInfo(data);
      const { list = {} } = data || {};
      const { profile = {} } = list || {};
      const { firstName, lastName } = profile || {};
      setCandiProfile({
        ...candiProfile,
        firstName: firstName,
        lastName: lastName,
      });
    });
  };

  const handleGetAssessOTP = () => {
    const { publicUuid } = routeParams || {};
    const sendOTPData = { publicUuid: publicUuid };
    let url = `${baseUrl}/codinginterviews/otp/generate`;
    let config = {
      headers: {
        "Content-Type": "application/json",
        //Authorization: authToken,
        // "Access-Control-Allow-Origin": "*",
      },
    };
    axios
      .post(url, sendOTPData, config)
      .then((res) => {
        const { data } = res || {};
        if (data?.message) {
          setEssentials({
            ...essentials,
            showSuccess: true,
            notifMsg: data?.message,
          });
          setTimeout(
            () =>
              setEssentials({
                ...essentials,
                showSuccess: false,
                notifMsg: "",
              }),
            2000
          );
        } else {
          setEssentials({
            ...essentials,
            showError: true,
            notifMsg: data && data.error && data.error.message,
          });
          setTimeout(
            () =>
              setEssentials({ ...essentials, showError: false, notifMsg: "" }),
            2000
          );
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleGettingData = () => {
    const { publicUuid } = routeParams || {};

    const validateOTPData = { publicUuid: publicUuid, enteredOtp: "codiOTP", bypassOtp: true };
    let url = `${baseUrl}/codinginterviews/otp/validate`;
    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
      .post(url, validateOTPData, config)
      .then((res) => {
        console.log("res==>",res)
        const { data } = res || {};
        if (!data?.error) {
          let isViewPage;
          if (data.codingInterviewInfo?.status === "completed") {
            isViewPage = true;
          }
          setIsOTPvalidated({ bool: true, data: data, isViewPage: isViewPage });
          setEssentials({
            ...essentials,
            showSuccess: true,
            notifMsg: data?.message,
          });
          setEssentials({
            ...essentials,
            showSuccess: true,
            notifMsg: data?.message,
          });
          setTimeout(
            () =>
              setEssentials({
                ...essentials,
                showSuccess: false,
                notifMsg: "",
              }),
            2000
          );
          setCodiOTP("");
        } else if (data?.error) {
          setIsOTPvalidated({ bool: false, data: undefined });
          setEssentials({
            ...essentials,
            showError: true,
            notifMsg: data && data.error && data.error.message,
          });
          setTimeout(
            () =>
              setEssentials({
                ...essentials,
                showError: false,
                notifMsg: data && data.error && data.error.message,
              }),
            2000
          );
        }
      })
      .catch((err) => console.error(err));
  };

  const handleLogin = () => {
    if(!isUserLogin){
      let url = `${dstaUrl}/professional/login`;
      const { publicUuid ,isUserLogin=false} = routeParams || {};
  
      url += '?sessionData=' + encodeURIComponent(publicUuid);

      window.location.href = url

    }else{
      handleGettingData();
    }
  }

  const handleValidateOTP = () => {
    const { publicUuid } = routeParams || {};

    const validateOTPData = { publicUuid: publicUuid, enteredOtp: codiOTP };
    let url = `${baseUrl}/codinginterviews/otp/validate`;
    let config = {
      headers: {
        "Content-Type": "application/json",
        // Authorization: authToken,
        "Access-Control-Allow-Origin": "*",
      },
    };
    axios
      .post(url, validateOTPData, config)
      .then((res) => {
        const { data } = res || {};
        if (codiOTP.length <= 0) {
          setEssentials({
            ...essentials,
            showError: true,
            notifMsg: translation("OTP field cannot be empty!"),
          });
          setTimeout(
            () =>
              setEssentials({
                ...essentials,
                showError: false,
                notifMsg: data && data.error && data.error.message,
              }),
            2000
          );
        } else if (!data?.error) {
          let isViewPage;
          if (data.codingInterviewInfo?.status === "completed") {
            isViewPage = true;
          }
          setIsOTPvalidated({ bool: true, data: data, isViewPage: isViewPage });
          setEssentials({
            ...essentials,
            showSuccess: true,
            notifMsg: data?.message,
          });
          setEssentials({
            ...essentials,
            showSuccess: true,
            notifMsg: data?.message,
          });
          setTimeout(
            () =>
              setEssentials({
                ...essentials,
                showSuccess: false,
                notifMsg: "",
              }),
            2000
          );
          setCodiOTP("");
        } else if (data?.error) {
          setIsOTPvalidated({ bool: false, data: undefined });
          setEssentials({
            ...essentials,
            showError: true,
            notifMsg: data && data.error && data.error.message,
          });
          setTimeout(
            () =>
              setEssentials({
                ...essentials,
                showError: false,
                notifMsg: data && data.error && data.error.message,
              }),
            2000
          );
        }
      })
      .catch((err) => console.error(err));
  };

  const handleOnChange = (e) => {
    setCodiOTP(e.target.value);
  };

  const onchangeLanguage = () => {
    setShowTranslationChange(!showTranslationChange);
  };

  const handleLanguageChange = () => {
    onchangeLanguage();
  };

  return (
    <Fragment>
      {!isOTPvalidated.bool ? (
        <>
          <div className="d-flex justify-content-end">
            <Button
              kind="tertiary"
              style={{
                position: "absolute",
                padding: "1rem 3rem",
                zIndex: "1111",
              }}
              onClick={() => handleLanguageChange()}
            >
              {selectedLang}
            </Button>
            <HeaderPanel
              aria-label="Change language"
              expanded={showTranslationChange}
              style={{ overflow: "hidden" }}
            >
              {showTranslationChange === true && (
                <LanguageChange
                  showLanguageModal={showTranslationChange}
                  onchangeLanguage={onchangeLanguage}
                />
              )}
            </HeaderPanel>
          </div>
          <div className="bx--grid m-0 p-0 xpa-overflow--hidden m-0 p-0">
            <div className="pt-0">
              <div
                className="bx--row sub_div_height_full overflow-hidden"
                style={{
                  minHeight: "100vh",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#623854",
                  }}
                  className="bx--col-lg-6 bx--col-md-12 border-page-division bx--col-md-12 d-flex align-items-center"
                >
                  <div className="d-flex flex-fill justify-content-center">
                    <img className="img_height" src={x0paWhite} alt="x0pa" />
                  </div>
                </div>
                <div
                  className="bx--col-lg-6 bx--col-md-12 d-flex flex-column justify-content-center"
                  style={{ minHeight: "100vh" }}
                >
                  <div className="m-0 p-0">
                    {essentials?.showError && (
                      <InlineNotification
                        lowContrast
                        kind="error"
                        title={essentials?.notifMsg}
                        subtitle=""
                        hideCloseButton
                      />
                    )}
                    {essentials?.showSuccess && (
                      <InlineNotification
                        lowContrast
                        kind="success"
                        title={essentials?.notifMsg}
                        subtitle=""
                        hideCloseButton
                      />
                    )}
                  </div>
                  <div className="">
                    <div className="mt-2 font-weight-bold h6">
                      {translation("Hi")}{" "}
                      <span className="text-capitalize">
                        {candiProfile?.firstName}
                      </span>{" "}
                      <span className="text-capitalize">
                        {candiProfile?.lastName}
                      </span>
                      ! 👋
                    </div>
                    <hr />
                    <div className="mt-2 mb-2 ">
                      {translation(
                        "Please follow these steps to get started with your Coding Interview"
                      )}
                    </div>
                    <div className="mt-2 mb-2 d-flex ">
                      <span className="xpa-vi--step-c p-1 d-inline-block">
                        <Number_132 />
                      </span>{" "}
                      <span className="d-inline-block ml-1">
                        {translation("require-an-OTP")}
                        <strong> {translation("send-otp")}</strong>{" "}
                        {translation("button-below")}
                      </span>
                    </div>
                    <div className="text-center">
                      <Button
                        kind="ghost"
                        size="small"
                        onClick={handleGetAssessOTP}
                      >
                        {translation("send-otp")}
                      </Button>
                    </div>
                    <div className="mt-2 mb-2  d-flex">
                      <span className="xpa-vi--step-c p-1">
                        <Number_232 />
                      </span>{" "}
                      <span className="d-inline-block ml-1 mt-2">
                        {translation("received-below")}
                      </span>
                    </div>

                    <div className="w-100 d-flex justify-content-center align-items-center">
                      <div>
                        <TextInput
                          id="xpa-vi--otp"
                          className="xpa-ts"
                          name="codiOTP"
                          placeholder={"VG1DE7U3"}
                          maxLength={12}
                          labelText={translation("OTP")}
                          hideLabel
                          value={codiOTP}
                          onChange={(e) => handleOnChange(e)}
                        />
                      </div>
                    </div>
                    <div className="mt-2 text-center">
                      <Button kind="primary" onClick={handleValidateOTP}>
                        {translation("Start")}
                      </Button>
                    </div>
                  </div>
                  <div className="mt-2 text-center font-weight-bold h6">
                    {translation("OR")}
                  </div>
                  <div className="mt-2 text-center">
                    <Button kind="primary" onClick={handleLogin}>
                      {translation("Login")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Editor
          validatedData={isOTPvalidated.data}
          isViewPage={isOTPvalidated.isViewPage}
        />
      )}
    </Fragment>
  );
};

export default CandidateCodingInterviewOTP;
