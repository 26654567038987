import { useState, useEffect } from "react";
import "./options.css"
import { connect } from 'react-redux'
import { Support } from "../Redux/Actions";
import { useTranslation } from 'react-i18next';

function Help(props) {
    const [state, setState] = useState("")
    const { t } = useTranslation();

    useEffect(() => {

        //let data = props.data;
        // if (data) {
        //      setState(data)
        //     
        // }

    }, [props.data]);

    return (
        <div className="outer-textarea">
            <div className="textarea" disabled>
                {t("Incase of any issues with this module, please contact and provide information such as the url",)}&nbsp;
                <a href="support@x0pa.com">support@x0pa.com</a>&nbsp;
                {t("screenshots and any additional information as required to clearly explain the issue")}.
            </div>
        </div>
    );
}


const mapStateToProps = (state) => ({
    data: state.Support,
})

const mapDispatchToProps = dispatch => ({
    help: (value) => (dispatch(Support(value))),
})

export default connect(mapStateToProps, mapDispatchToProps)(Help)
