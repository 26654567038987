import { useState, useEffect } from "react";
import Question from "./Question";
import Results from "./Results";
import Notes from "./Notes";
import Help from "./Help";
import "./options.css"
import "../Coder/Editor.css"
import { useTranslation } from 'react-i18next';

export default function OutputContainer(props) {
  const [tabSelect, setTabSelect] = useState("Question");
  const [showResult, setShowResult] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (props.result === true || tabSelect === "Results") {
      setTabSelect("Results")
    }
  }, [props.result]);

  useEffect(() => {
    (props.tabSelected === "codeEditor") ? setShowResult(true) : setShowResult(false);
  }, [props.tabSelected]);

  const handleSelect = (e) => {
    setTabSelect(e.target.id)
  }

  return (
    <>
      <div id="output-container">
        <nav className="navbar navbar-expand-sm">
          <ul className="navbar-nav">
            <li className="nav-item">
              <button className={tabSelect === "Question" ? "btn-select text-white" : "btn-white text-white"} id="Question" onClick={handleSelect} >{t("Question")}</button>
            </li>
            {showResult ?
              <li className="nav-item">
                <button className={tabSelect === "Results" ? "btn-select text-white" : "btn-white text-white"} id="Results" onClick={handleSelect}>{t("Results")}</button>
              </li>
              : <></>
            }
            <li className="nav-item">
              <button className={tabSelect === "Notes" ? "btn-select text-white" : "btn-white text-white"} id="Notes" onClick={handleSelect}>{t("Notes")}</button>
            </li>
            <li className="nav-item">
              <button type="button" className={tabSelect === "Help/Support" ? "btn-select text-white" : "btn-white text-white"} id="Help/Support" onClick={handleSelect}>{t("Help-support")}</button>
            </li>
          </ul>
        </nav>
        <div className="text-white" id="body-container">
          {tabSelect === "Question" ? <Question questionData={props.questionData} isViewPage={props.isViewPage} questionResponses={props.questionResponses} /> : ""
          }
          {tabSelect === "Results" ? <Results output={props.output} result={props.result} /> : ""
          }
          {tabSelect === "Notes" ? <Notes isViewPage={props.isViewPage} /> : ""
          }
          {tabSelect === "Help/Support" ? <Help isViewPage={props.isViewPage} /> : ""
          }
        </div>
      </div>
    </>
  );
}
