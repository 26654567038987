import React, { Component } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import Excali from "../Excalidraw/Excalidraw";
import "./Editor.css";
import OutputContainer from "../OutputContainer/outputContainer";
import { connect } from "react-redux";
import { Note, Result, Runcode, Support, whiteBoard } from "../Redux/Actions";
import { baseUrl } from "../utils/config";
import { authToken } from "../utils/token";
import SubmitSuccess from "./submitSuccess";
import { ComposedModal, ModalHeader, ModalBody, ModalFooter, Button, HeaderPanel, Header } from "carbon-components-react";
import LanguageChange from "./Languages";
import { withTranslation } from 'react-i18next';
import i18nHelper from './i18n';
class Editor extends Component {
  constructor(props) {
    super(props);
    let companyId = this.props.validatedData.codingInterviewGroupInfo.companyId;
    i18nHelper(companyId || "0");
    const languages = {
      63: "javascript",
      74: "typescript",
      48: "c",
      52: "c++",
      70: "python",
      62: "java",
      60: "go",
      68: "php",
      72: "ruby",
      73: "rust",
      58: "erlang",
      51: "c#"
    };

    const themes = [
      "monokai",
      "github",
      "tomorrow",
      "kuroir",
      "twilight",
      "xcode",
      "textmate",
      "solarized_dark",
      "solarized_light",
      "terminal"
    ];

    const sampleCode = {
      63: `console.log("hello, world!");`,
      74: `console.log("hello, world!");`,
      48: `#include <stdio.h>
              int main(void) {
              printf("hello, world!\n");
              return 0;
         }`,
      52: `#include <iostream>
              int main() {
              std::cout << "hello, world!" << std::endl;
              return 0;
         }`,
      70: `print("hello, world!")`,
      62: `public class Main {
              public static void main(String[] args) {
              System.out.println("hello, world!");
            }
         }`,
      60: `package main
             import "fmt"
          func main() {
             fmt.Println("hello, world!")
         }`,
      68: `<?php
             print("hello, world!\n");
          ?>`,
      72: `puts "hello, world!"`,
      73: `fn main() {
             println!("hello, world!");
         }`,
      58: `main(_) ->
             io:fwrite("hello, world!\n").`,
      51: `public class Hello {
              public static void Main() {
              System.Console.WriteLine("hello, world!");
            }
         }`,
    }

    this.state = {
      theme: "monokai",
      mode: 63,
      languages: languages,
      sampleCode: sampleCode,
      themes: themes,
      editor: "",
      session: "",
      codeEditor: "",
      bool: true,
      show: false,
      activeButton: undefined,
      tabSelected: "codeEditor",
      output: "",
      result: false,
      questionData: [],
      codingResponsesAsObj: [],
      submitted: false,
      submitSuccess: false,
      doRunCode: false,
      runCodeData: "",
      enableCodeEditor: false,
      enableWhiteBoard: false,
      showTranslationChange: false,
      modeChange: false,
      languageChange: ""
    }

    this.setMode = this.setMode.bind(this);
    this.setTheme = this.setTheme.bind(this);
    this.runCode = this.runCode.bind(this);
    this.runCodeApi = this.runCodeApi.bind(this);
  }

  setMode = (e) => {
    let { codeEditor } = this.state;

    if (codeEditor) {
      this.setState({ modeChange: true, languageChange: e.target.value });
    }
    else {
      this.setState({
        mode: e.target.value,
      })
    }
  }

  languageChangeHandler = () => {
    let { languageChange } = this.state;
    this.setState({ codeEditor: "", modeChange: false, mode: languageChange });
  }

  setTheme = (e) => {
    this.setState({
      theme: e.target.value
    });
  }

  runCode = () => {

    let mode = this.state.mode
    let code = this.state.editor.getValue();
    this.props.runcode(code);
    this.setState({ result: true, codeEditor: code });
    let candidateEmail = this.props?.validatedData?.codingInterviewInfo?.candidateEmail;
    let codingInterviewGroupId = this.props?.validatedData?.codingInterviewInfo?.codingInterviewGroupId;
    let codingInterviewId = this.props?.validatedData?.codingInterviewInfo?.codingInterviewId;
    let problemId = this.props?.hybridQuestionId;

    if (code && code.length) {
      var postOptions = {
        method: 'POST',
        url: `${baseUrl}/codinginterviewresponses/post`,
        // headers: {
        //   "Authorization": authToken,
        // },
        data: {
          candidateEmail: candidateEmail,
          problemId: problemId,
          codingInterviewGroupId: codingInterviewGroupId,
          codingInterviewId: codingInterviewId,
          languageId: mode,
          sourceCode: code
        }
      };

      axios.request(postOptions)
        .then((response) => {
          let tokenData = response.data;
          let data = {
            responseId: tokenData?.responseId,
            tokens: [tokenData?.tokens[0]?.token]
          }
          // if (tokenData.tokens.length) {
            this.runCodeApi(data);
            this.setState({ runCodeData: data });
          // }
        }).catch(function (error) {
          console.error(error);
        });
    }
  }

  runCodeApi = (data, time) => {

    let { codingResponsesAsObj } = this.state;

    var getOptions = {
      method: 'post',
      url: `${baseUrl}/codinginterviewresponses/poll`,
      data: data
    }
    setTimeout(() => {
      axios.request(getOptions).then((response) => {
        let out = response?.data?.submissions[0];
        let codingRes = codingResponsesAsObj[this.props.hybridQuestionId];
        if (out.status.id === 3) {
          let output = out.stdout; //atob(out.stdout);
          this.setState({ doRunCode: false, output: output, result: false });
          if (codingRes) codingRes.stdOut = output;
        } else if (out.status.id === 2 || out.status.id === 1) {
          let state = out.status.description;
          this.setState({ doRunCode: true, output: state, result: true });
          if (codingRes) codingRes.stdOut = state;
        } else {
          let err = out.status.description;
          this.setState({ doRunCode: false, output: err, result: false });
          if (codingRes) codingRes.stdOut = err;
        }
      }).catch(function (error) {
        console.error(error);
      });
    }, time || 1000);
  }

  componentDidUpdate(prevProps, prevState) {
    let { doRunCode, runCodeData } = this.state;
    let { codingResponsesAsObj } = this.state;

    if (this.props.hybridQuestionId !== prevProps.hybridQuestionId) {
      let codingRes = codingResponsesAsObj[this.props.hybridQuestionId];
      if (codingRes) {
        if (!this.props.isViewPage) {
          let code = this.state.editor.getValue();
          this.props.runcode(code);
          this.updateResponseObject(prevProps.hybridQuestionId);
          this.submitHandler(undefined, prevProps.hybridQuestionId);
        } else {
          if (codingRes.stdOut === null && codingRes.submissionTokens?.tokens?.length) {
            let data = {
              responseId: codingRes?.responseId,
              tokens: [codingRes?.submissionTokens.tokens[0]]
            }
            this.runCodeApi(data, 0)
          }
        }

        this.handleEditorData(codingRes);
      }
    }

    if (doRunCode) {
      this.runCodeApi(runCodeData, 1000);
    }
  }

  updateResponseObject = (problemId) => {
    let { codingResponsesAsObj, mode } = this.state;
    let codingRes = codingResponsesAsObj[problemId];

    if (codingRes) {
      codingRes.languageId = mode;
      codingRes.sourceCode = this.props.code;
      codingRes.whiteboardSvg = this.props.svg;
      codingRes.whiteboardJson = {
        data: JSON.stringify(this.props.whiteBoard),
      };
      codingRes.notes = this.props.notes;
      codingRes.help = this.props.helpData;
    }
  }

  handleEditorData = (res) => {
    if (res) {
      this.setState({ codeEditor: res?.sourceCode, mode: res?.languageId || "63", output: res.stdOut });
      this.props.note(res?.notes || "");
      this.props.help(res?.help || "");
      let boardData = JSON.parse(res?.whiteboardJson?.data);
      this.props.board(boardData || []);
    }
  }

  onChangeCodeEditor = () => {
    let code = this.state.editor.getValue();

    let { codingResponsesAsObj } = this.state;
    let codingRes = codingResponsesAsObj[this.props.hybridQuestionId];

    if (codingRes) codingRes.sourceCode = code;

    this.setState({ codeEditor: code });
    this.props.runcode(code);
  }

  componentDidMount() {

    let { validatedData } = this.props;

    this.codeEditorHandeler();
    let enableCodeEditor, enableWhiteBoard, tabSelected;


    if (validatedData.codingInterviewGroupInfo.enableWhiteBoard) {
      enableWhiteBoard = true;
      tabSelected = "";
    }

    if (validatedData.codingInterviewGroupInfo.enableCodeEditor) {
      enableCodeEditor = true;
      tabSelected = "codeEditor";
    }

    if (!(enableWhiteBoard || enableCodeEditor)) {
      enableCodeEditor = true;
      tabSelected = "codeEditor";
    }

    this.setState({ questionData: validatedData.questions, enableCodeEditor: enableCodeEditor, enableWhiteBoard: enableWhiteBoard, tabSelected: tabSelected });

    let codingResponsesAsObj;
    if (validatedData.codingInterviewResponses.length) {
      codingResponsesAsObj = this.handleArrayToObject(validatedData);
    } else {
      codingResponsesAsObj = this.codingInterviewResponsesData(validatedData);
    }

    let codingRes = codingResponsesAsObj[this.props.hybridQuestionId];
    if (codingRes) {
      if (this.props.isViewPage) {
        if (codingRes.stdOut === null && codingRes.submissionTokens?.tokens?.length) {
          let data = {
            responseId: codingRes?.responseId,
            tokens: [codingRes?.submissionTokens.tokens[0]]
          }
          this.runCodeApi(data, 0)
        }
      }
      this.handleEditorData(codingRes);
    }

    this.setState({
      isViewPage: this.props.validatedData.isViewPage
    });

  }

  handleArrayToObject = (codingRes) => {
    let data = {}
    let codingResponses = codingRes.codingInterviewResponses;
    codingResponses.map((res) => {
      data[res.problemId] = res;
    })

    this.setState({ codingResponsesAsObj: data });
    return data;
  }

  codingInterviewResponsesData = (validateData) => {
    let data = {};
    let codingQuestions = validateData.questions;
    codingQuestions.map((q) => {

      data[q.hybridQuestionId] = {
        languageId: "",
        sourceCode: "",
        whiteboardSvg: "",
        whiteboardJson: { data: "[]" },
        notes: "",
        help: ""
      }
    });

    this.setState({ codingResponsesAsObj: data });
    return data;
  }
  getExampleRef() {
    var ref = window.firebase.database().ref();
    var hash = window.location.hash.replace(/#/g, '');
    if (hash) {
      ref = ref.child(hash);
    } else {
      ref = ref.push(); // generate unique location.
      window.location = window.location + '#' + ref.key; // add it as a hash to the URL.
    }
    if (typeof console !== 'undefined') {
      console.log('Firebase data: ', ref.toString());
    }
    return ref;
  }

  hideModal = () => {
    this.setState({ submitted: false, modeChange: false })
  }

  activeTab = (e) => {
    this.setState({ tabSelected: e.target.id, bool: true }, () => {
      if (e.target.id === "codeEditor") {
        this.codeEditorHandeler();
        this.setEditorValue();
      }
    });
  }

  codeEditorHandeler = () => {
    const config = {
      apiKey: "AIzaSyBvbwPGLAE8YXzR_WAVWt13VM1ZOjPnx-0",
      authDomain: "coder-d5324.firebaseapp.com",
      databaseURL: "https://coder-d5324-default-rtdb.firebaseio.com",
    };

    if (!window.firebase.apps.length) {
      window.firebase.initializeApp(config);
    }
    var firepadRef = this.getExampleRef();

    //// Create ACE
    var editor = window.ace.edit("firepad-container");
    var session = editor.getSession();

    //const curMgr = new AceCollabExt.AceMultiCursorManager(editor.getSession());

    window.Firepad.fromACE(firepadRef, editor);
    if (this.state.bool) {
      this.setState({ editor: editor, session: session, bool: false })
    }

    editor.setShowPrintMargin(false);
    document.getElementsByClassName("powered-by-firepad")[0].remove();
  }


  submitHandler = (isInterviewCompleted, problemId) => {
    let validatedData = this.props.validatedData;
    let data = {
      isFinalSubmission: true,
      isInterviewCompleted: isInterviewCompleted,
      candidateEmail: validatedData?.codingInterviewInfo?.candidateEmail,
      problemId: problemId || this.props.hybridQuestionId,
      codingInterviewId: validatedData?.codingInterviewInfo?.codingInterviewId,
      codingInterviewGroupId: validatedData?.codingInterviewInfo?.codingInterviewGroupId,
      languageId: this.state.mode,
      sourceCode: this.state.codeEditor,
      whiteboardSvg: JSON.stringify(this.props.svg),
      whiteboardJson: {
        data: JSON.stringify(this.props.whiteBoard),
      },
      notes: this.props.notes,
      help: this.props.helpData,
      result: this.state.output
    }


    let url = `${baseUrl}/codinginterviewresponses/post`;

    axios(url, {
      method: 'POST',
      data: data,
      headers: {
        'Content-Type': 'application/json',
        // "Authorization": authToken,
        "Access-Control-Allow-Origin": "*"
      }
    })
      .then(data => {
        console.log('Success:', data);
        localStorage.setItem('1', JSON.stringify());
        localStorage.clear();
        if (isInterviewCompleted) {
          this.setState({ submitted: false, submitSuccess: true });
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  confirmSubmit = () => {
    this.setState({ submitted: true })
  }

  Codi = () => {
    let { isViewPage, t } = this.props;
    let { languages, tabSelected } = this.state;
    return (
      <div style={{ height: "100%" }}>
        {isViewPage ? <></> :
          <div className="top-bar">
            <span>
              <button onClick={this.runCode} className="btn-danger p-2 text-white" id="" >{t("Run-Code")}</button>
            </span>
            <div className="select-name">
              <label className="text-white">{t("Mode")}</label>
              <span className="select">
                <select
                  name="mode"
                  onChange={this.setMode}
                  value={this.state.mode}
                >
                  {Object.keys(languages).map(i => (
                    <option key={i} value={i}>
                      {languages[i]}
                    </option>
                  ))}
                </select>
              </span>

              <label className="text-white">{t("Theme")}</label>
              <span className="select">
                <select
                  name="Theme"
                  onChange={this.setTheme}
                  value={this.state.theme}
                >
                  {this.state.themes.map(lang => (
                    <option key={lang} value={lang}>
                      {lang}
                    </option>
                  ))}
                </select>
              </span>
            </div>
          </div>}
        <div id="firepad-container" className="editor" onBlur={this.onChangeCodeEditor} style={isViewPage ? { height: "100%" } : { height: "91.5%" }}></div>
      </div>
    )
  }

  setEditorValue = () => {
    let { editor, session, mode, theme, languages, codeEditor, sampleCode } = this.state;

    if (editor && session) {
      editor.setTheme("ace/theme/" + theme);
      session.setUseWrapMode(true);
      session.setUseWorker(false);
      session.setMode("ace/mode/" + languages[mode]);
      editor.setValue(codeEditor || sampleCode[mode]);
      editor.$blockScrolling = Infinity;
      editor.setReadOnly(this.props.isViewPage ? true : false);
    }
  }

  handleLanguageChange = () => {
    this.onchangeLanguage();
  };

  onchangeLanguage = () => {
    this.setState((prevState) => {
      return {
        showTranslationChange: !prevState.showTranslationChange,
      };
    });
  };

  render() {

    let { questionData, result, output, submitted, submitSuccess, tabSelected, enableCodeEditor, enableWhiteBoard, showTranslationChange, modeChange } = this.state;
    let { isViewPage, selectedLang, t } = this.props;

    if (tabSelected === "codeEditor") {
      this.setEditorValue();
    }

    return (
      <>
        {!submitSuccess ?
          <div className="body">
            <div className="bx--header justify-content-between" aria-label="X0PA Recruiter">
              <nav style={{ backgroundColor: "rgb(98, 59, 88)" }} className="navbar navbar-expand-sm mt-1">
                <ul className="navbar-nav">
                  <span className="nav-item">
                    <label className="nav-link" ><img className="xpa-top-header--logo" src="https://live.x0pa.ai/static/media/x0pa-white.61894431.png" alt="logo" /></label>
                  </span>
                </ul>
                <button type="button" onClick={this.handleLanguageChange} className="languageChange">{selectedLang}</button>
              </nav>
            </div>
            <>
              <HeaderPanel
                aria-label="Change language"
                expanded={showTranslationChange}
                style={{ overflow: 'hidden' }}>
                {showTranslationChange === true && (
                  <LanguageChange
                    showLanguageModal={showTranslationChange}
                    onchangeLanguage={this.onchangeLanguage}
                  />
                )}
              </HeaderPanel>
            </>
            <div className="topbar-buttons">
              <span className="ml-3">
                {enableCodeEditor ? <button className={tabSelected === "codeEditor" ? "btn-light" : "btn-default"} value={tabSelected} id="codeEditor" onClick={this.activeTab}>{t("Code-Editor")}</button> : <></>}
                {enableWhiteBoard ? <button className={tabSelected === "whiteBoard" ? "btn-light" : "btn-default"} value={tabSelected} id="whiteBoard" onClick={this.activeTab}>{t("White-Board")}</button> : <></>}
              </span>
              {isViewPage ? <></> :
                <button className="submit-button mr-3" onClick={this.confirmSubmit}>{t("Submit-Solution")}</button>
              }
            </div>
            <div className="editor-area">
              <div id="input-container">
                {tabSelected === "codeEditor" ? this.Codi() : <Excali isViewPage={isViewPage} />}
              </div>
              {questionData.length > 0 && <OutputContainer output={output} result={result} questionData={questionData} isViewPage={isViewPage} tabSelected={tabSelected} />}
            </div>
            {/* <div className="Modal">
              <Modal show={show} onHide={this.hideModal}>
                <Modal.Body>
                  <button className={isViewPage? "btn-cancel mb-2 mr-2" : "btn-cancel mt-4 mr-2"} onClick={this.hideModal}>X</button>
                  {<Excali isViewPage={isViewPage} />}
                </Modal.Body>
              </Modal>
            </div> */}
            <div>
              <ComposedModal size="sm" open={submitted} preventCloseOnClickOutside={true} onClose={this.hideModal}>
                <ModalHeader />
                <ModalBody>
                  <p className="bx--modal-content__text">
                    {t("Are you sure want to submit")}
                  </p>
                </ModalBody>
                <ModalFooter>
                  <Button kind="secondary" onClick={this.hideModal}>{t("cancel")}</Button>
                  <Button kind="primary" onClick={() => this.submitHandler(true)}>{t("submit")}</Button>
                </ModalFooter>
              </ComposedModal>
            </div>
            <div>
              <ComposedModal size="sm" open={modeChange} preventCloseOnClickOutside={true} onClose={this.hideModal}>
                <ModalHeader />
                <ModalBody>
                  <p className="bx--modal-content__text">
                    {t("Changing the language will remove the code entered")}
                  </p>
                </ModalBody>
                <ModalFooter>
                  <Button kind="secondary" onClick={this.hideModal}>{t("cancel")}</Button>
                  <Button kind="primary" onClick={() => this.languageChangeHandler(true)}>{t("ok")}</Button>
                </ModalFooter>
              </ComposedModal>
            </div>
          </div >
          :
          <SubmitSuccess />}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  helpData: state.Support,
  notes: state.Notes,
  code: state.runCode,
  question: state.question,
  whiteBoard: state.whiteBoard,
  svg: state.svg,
  hybridQuestionId: state.hybridQuestionId,
  selectedLang: state.ProductLanguage.selectedLang,
})

const mapDispatchToProps = dispatch => ({
  runcode: (value) => (dispatch(Runcode(value))),
  help: (value) => (dispatch(Support(value))),
  note: (value) => (dispatch(Note(value))),
  board: (value) => (dispatch(whiteBoard(value))),
  data: (value) => (dispatch(Result(value)))
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Editor));