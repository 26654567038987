import { combineReducers } from "redux";
import { notesReducer, supportReducer, runcodeReducer, questionReducer, boardReducer,
         svgReducer, resultReducer, hybridQuestionIdReducer, selectedIndexIdReducer, submitCompleteReducer, ProductLanguage } from "./Reducer";

const rootReducers = combineReducers({
    Notes: notesReducer,
    Support: supportReducer,
    runCode: runcodeReducer,
    question: questionReducer,
    result: resultReducer,
    whiteBoard: boardReducer,
    svg: svgReducer,
    hybridQuestionId:hybridQuestionIdReducer,
    hybridQuestionIndex: selectedIndexIdReducer,
    submitCompleted: submitCompleteReducer,
    ProductLanguage: ProductLanguage
})

export default rootReducers;