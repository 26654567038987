import { useState, useEffect } from "react";
import "./options.css"
import { connect } from 'react-redux'
import { Note } from "../Redux/Actions";
import { useTranslation } from 'react-i18next';

function Notes(props) {
    const [state, setState]=useState("")
    const [isViewPage, setisViewPage]=useState(undefined)
    const { t: translation } = useTranslation();

    const textHandler = (e) => {
        setState(e.target.value);
        props.note(e.target.value)
    }

    useEffect(() => {
        let data = props.notes;
        if (data) {
            setState(data);
            setisViewPage(props.isViewPage)
        }
    }, [props.notes])


    return (
        <>
            <textarea className="textarea" onChange={textHandler} placeholder={translation("Enter your notes / comments to be shared with the evaluation panel...")} value={state} disabled={isViewPage? true : false}>

            </textarea>
        </>
    );
}

const mapStateToProps = (state) => ({
    notes: state.Notes,
})

const mapDispatchToProps = dispatch => ({
    note: (value) => (dispatch(Note(value))),
})

export default connect(mapStateToProps, mapDispatchToProps)(Notes)
